var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "liveinfo-form", attrs: { "no-body": "" } },
    [
      _c(
        "div",
        { staticClass: "m-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                  attrs: { cols: "12", md: "10" },
                },
                [
                  _c("b-form-input", {
                    staticClass: "el-def",
                    attrs: { placeholder: "제약사명" },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
                  _c("v-select", {
                    staticClass: "invoice-filter-select el-def",
                    attrs: {
                      dir: "ltr",
                      options: _vm.statusOptions,
                      placeholder: "포털명",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selected-option",
                        fn: function (ref) {
                          var label = ref.label
                          return [
                            _c(
                              "span",
                              { staticClass: "text-truncate overflow-hidden" },
                              [_vm._v(" " + _vm._s(label) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.statusFilter,
                      callback: function ($$v) {
                        _vm.statusFilter = $$v
                      },
                      expression: "statusFilter",
                    },
                  }),
                  _c("b-form-datepicker", {
                    staticClass: "el-def",
                    attrs: {
                      id: "datepicker-placeholder1",
                      placeholder: "등록일(From)",
                    },
                  }),
                  _c("label", { staticClass: "ml-25 mr-50" }, [_vm._v("~")]),
                  _c("b-form-datepicker", {
                    staticClass: "el-def",
                    attrs: {
                      id: "datepicker-placeholder2",
                      placeholder: "등록일(To)",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                  attrs: { cols: "12", md: "2" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-search",
                      attrs: {
                        variant: "primary",
                        to: { name: "apps-invoice-add" },
                      },
                    },
                    [_vm._v(" 조회 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
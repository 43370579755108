<template>
  <b-card
    no-body
    class="liveinfo-form"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="10"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-input
            v-model="companyName"
            placeholder="제약사명"
            class="el-def"
          />
          <v-select
            v-model="statusFilter"
            dir="ltr"
            :options="statusOptions"
            class="invoice-filter-select el-def"
            placeholder="포털명"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </v-select>
          <b-form-datepicker
            id="datepicker-placeholder1"
            class="el-def"
            placeholder="등록일(From)"
          />
          <label class="ml-25 mr-50">~</label>
          <b-form-datepicker
            id="datepicker-placeholder2"
            class="el-def"
            placeholder="등록일(To)"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-invoice-add' }"
            class="btn-search"
          >
            조회
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    vSelect,
  },
  setup() {
    const companyName = ref('')
    const companyList = ['동화약품', '녹십자', 'GC', '유한양행', '종근당']
    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    return {
      companyName,
      companyList,
      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.invoice-filter-select {
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
